import CoisLists from "@/components/widgets/coin-list";
import CoisCutting from "@/components/widgets/coin-cutting-list";
import FinishedGoods from "@/components/widgets/finished-goods";
import GoodsPE from "@/components/widgets/goods-pe";
import DatePicker from "vue2-datepicker";
import Vue, { computed } from "vue";
import SortedTablePlugin from "vue-sorted-table";
import _ from "lodash";
import CutType from "@/components/cuttype";
import VueSimpleSuggest from "vue-simple-suggest";
import SettingPopup from "@/components/dialogs/bill-item";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    DatePicker,
    CoisLists,
    CoisCutting,
    FinishedGoods,
    CutType,
    GoodsPE,
    VueSimpleSuggest,
    SettingPopup,
  },
  props: {
    productItem: {
      type: Object,
    },
  },
  data() {
    return {
      rollIndex: -1,
      isSettingOpened: false,
      settingData: {},
      settingIndex: -1,
      allCode: [],
      codeItems: [],
      sortBy: "",
      sortDesc: false,
      create: {
        Id: null,
        mode: "create",
        state: false,
      },
      product_fields: [
        {
          key: "index",
          label: "#",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "td-top text-xs-1/2",
        },
        {
          key: "code",
          label: "รหัสสินค้า",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
        },
        {
          key: "description",
          label: "รายละเอียด",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
        },
        // {
        //   key: "roll",
        //   label: "ม้วน",
        //   thClass: "font-weight-bold text-xs-1/2",
        //   tdClass: "text-gray-400 td-top text-xs-1/2",
        // },
        {
          key: "length",
          label: "ความยาว",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
        },
        {
          key: "quantity",
          label: "จำนวน",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-right text-gray-400 td-top text-xs-1/2",
        },
        {
          key: "unit_name",
          label: "หน่วย",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-right text-gray-400 td-top text-xs-1/2",
        },
        {
          key: "total_length",
          label: "ปริมาณรวม",
          thClass: "font-weight-bold text-xs-1/2",
          tdClass: "text-right text-gray-400 td-top text-xs-1/2",
        },
        // {
        //   key: "remark",
        //   label: "รายละเอียดเพิ่มเติม",
        //   thClass: "font-weight-bold text-xs-1/2",
        //   tdClass: "text-gray-400 td-top text-xs-1/2",
        // },
        {
          key: "action",
          label: "#",
          thClass: "text-center font-weight-bold text-xs-1/2",
          tdClass: "text-gray-400 td-top text-xs-1/2",
        },
      ],
      insertAfterRowIndex: 0,
      selectedRows: [],
      goodsPe: false,
      isShowingMiscellaneous: false,
      coinlistEnabled: false,
      coinlistEnabled2: false,
      coinlistEnabled3: false,
      coincuttingEnabled: false,
      currentCoil: null,
      currentCoil2: null,
      currentCoil3: null,
      isPE: true,
      isPU: false,
      thickness: null,
      insulator_material: null,
      insulatorCoil: null,
      formData: {
        bill_cut_pattern: null, // รูปแบบการตัด
        bill_check_pe: false, // ติด PE ?
        bill_code: "", // รหัส
        bill_name: "", // ชื่อ
        bill_price_m: 0, // ราคา/ม.
        bill_except_e: 0, // เว้นปลาย(cm)
        bill_except_h: 0, // เว้นหัว(cm)
        bill_uuid: "",
        bill_product_note: "", // หมายเหตุรายการ
        bill_total_qty: 0, // รวมจำนวน(หน่วย)
        bill_total_length: 0, // รวมยาว(m)
        bill_length_use: 0, // รวมยาวใช้จริง(m)
        bill_products: [], // สินค้า (ตาราง)
        product_code: "", // รหัสสินค้า/บาร์โค๊ด
        product_name: "", // รายละเอียดสินค้า/รายการสินค้า
        balance: 0,
        product_length: 1, // ยาว(ม)
        product_qty: 1, // จำนวน
        sale_unit: "", // หน่วยนับขาย
        price_per_unit: 0, // ราคา/หน่วย
        unit: "", // หน่วย
        total_price: 0, // จำนวนเงิน
        remaining_length: 0,
        length_being_cut: 0,
        after_being_cut: 0,
        vatable: 0,
        bill_discount_amt: 0,

        bill_summary: 0,
        // bill_deposit_amt: 0,
        bill_nettotal_amt: 0,
        bill_before_vat: 0,
        bill_tax_type: 0,
        bill_value_added_tax: 0,
        bill_grand_total: 0,
      },
      form: {
        vatable: 0,
      },
      textDescription: "",
    };
  },
  computed: {
    fullNameWithCoil: function () {
      if (this.currentCoil) {
        const insulation = this.formData.bill_check_pe;
        var insulationText = "";
        if (insulation) {
          if (this.isPE) {
            insulationText +=
              "ติดฉนวน PE " +
              (this.thickness != null
                ? this.thicknessOptions.find((o) => o.uuid === this.thickness)
                    .name
                : "");
          }
          if (this.isPU) {
            insulationText +=
              "ติดฉนวน PU " +
              (this.thickness != null
                ? this.thicknessOptions2.find((o) => o.uuid === this.thickness)
                    .name
                : "");
            insulationText +=
              this.insulator_material != null
                ? " " +
                  this.insulatorMaterialOptions.find(
                    (o) => o.uuid === this.insulator_material
                  ).name
                : "";
            insulationText += this.currentCoil2
              ? " ใช้ท้อง " +
                this.currentCoil2?.size +
                " " +
                this.currentCoil2?.color +
                " " +
                this.currentCoil2?.brand
              : "";
          }
        }

        return (
          this.currentCoil?.size +
          " " +
          this.currentCoil?.color +
          " " +
          this.currentCoil?.brand +
          " \n" +
          // (this.formData.bill_cut_pattern
          //   ? this.skuOptions.find(
          //       (o) => o.uuid === this.formData.bill_cut_pattern
          //     ).name
          //   : "") +
          // " " +
          insulationText
        );
      } else {
        return "";
      }
    },
    // rows() {
    //   return this.formData.bill_products.length;
    // },
    // totalPrice() {
    //   this.formData.total_price = Number(
    //     (
    //       this.formData.product_length *
    //       this.formData.product_qty *
    //       this.formData.price_per_unit
    //     ).toFixed(2)
    //   );
    //   return isNaN(this.formData.total_price) ? 0 : this.formData.total_price;
    // },
    subtotal: function () {
      const total = this.formData.bill_products.reduce(
        (accumulator, object) => {
          return parseFloat(accumulator) + parseFloat(object.total_price);
        },
        0
      );
      if (this.formData.bill_tax_type === 2) {
        this.formData.bill_nettotal_amt = parseFloat(total);
        return parseFloat(total);
      } else {
        this.formData.bill_nettotal_amt = total;
        return total;
      }
    },
    beforeVat: function () {
      const total = this.formData.bill_products.reduce(
        (accumulator, object) => {
          return parseFloat(accumulator) + parseFloat(object.total_price);
        },
        0
      );
      if (this.formData.bill_tax_type === 2) {
        this.formData.bill_before_vat = (parseFloat(total) * 100) / 107;
        return (
          (parseFloat(total - this.formData.bill_discount_amt) * 100) / 107
        );
      } else {
        this.formData.bill_before_vat = total;
        return total - this.formData.bill_discount_amt;
      }
    },
    vat: function () {
      const total = this.formData.bill_products.reduce(
        (accumulator, object) => {
          return parseFloat(accumulator) + parseFloat(object.total_price);
        },
        0
      );
      if (this.formData.bill_tax_type === 1) {
        this.formData.bill_value_added_tax = parseFloat(total) * 0.07;
        return parseFloat(total - this.formData.bill_discount_amt) * 0.07;
      } else if (this.formData.bill_tax_type === 2) {
        // return (parseFloat(total) * 7) / 107;
        this.formData.bill_value_added_tax =
          (parseFloat(total - this.formData.bill_discount_amt) * 7) / 107;
        return (parseFloat(total - this.formData.bill_discount_amt) * 7) / 107;
      } else {
        this.formData.bill_value_added_tax = 0;
        return 0;
      }
    },
    depositAmount: function () {
      return this.$parent.formData.bill_deposit_amt;
    },
    total: function () {
      const total = this.formData.bill_products.reduce(
        (accumulator, object) => {
          return parseFloat(accumulator) + parseFloat(object.total_price);
        },
        0
      );
      var returnData = 0;
      if (this.formData.bill_tax_type === 1) {
        returnData = parseFloat(total - this.formData.bill_discount_amt) * 1.07;
        if (this.$parent.formData.bill_deposit_amt > 0)
          returnData =
            parseFloat(returnData) -
            parseFloat(this.$parent.formData.bill_deposit_amt);
        this.formData.bill_grand_total = returnData;
        return returnData;
      } else if (this.formData.bill_tax_type === 2) {
        returnData = parseFloat(total - this.formData.bill_discount_amt);
        if (this.$parent.formData.bill_deposit_amt > 0)
          returnData =
            parseFloat(returnData) -
            parseFloat(this.$parent.formData.bill_deposit_amt);
        this.formData.bill_grand_total = returnData;
        return returnData;
      } else {
        returnData = total - this.formData.bill_discount_amt;
        if (this.$parent.formData.bill_deposit_amt > 0)
          returnData =
            parseFloat(returnData) -
            parseFloat(this.$parent.formData.bill_deposit_amt);
        this.formData.bill_grand_total = returnData;
        return returnData;
      }
    },
  },
  watch: {
    productItem: {
      handler(newValue, oldValue) {
        if (this.$route.params?.id) {
          this.formData = newValue;
        }
      },
      deep: true,
    },
    formData: {
      handler(newValue, oldValue) {
        this.$emit("billdetail", {
          detail: newValue,
        });
      },
      deep: true,
    },
  },
  methods: {
    async simpleSuggestionList() {
      try {
        const result = await this.$axios.get(`${this.$host}/api/v1/skus`);
        if (result.status === 200) {
          const data = result.data.data;
          // this.allCode = data
          // this.codeItems = data.map(d => d.full_code)
          return data;
        }
      } catch (error) {
        return [];
        // this.onExceptionHandler(error.response.data.message);
      }
      // return [
      //   'Vue.js',
      //   'React.js',
      //   'Angular.js',
      //   'Vue.js',
      //   'React.js',
      //   'Angular.js',
      //   'Vue.js',
      //   'React.js',
      //   'Angular.js',
      //   'Vue.js',
      //   'React.js',
      //   'Angular.js'
      // ]
    },
    checkSelect(e, index) {
      var checkIfSkuExisted = this.skuOptions.findIndex(
        (p) => p.full_code === e
      );
      if (checkIfSkuExisted === -1) {
        this.formData.bill_products[index].sku_id = null;
        this.formData.bill_products[index].code_description = "";
      } else {
        this.formData.bill_products[index].sku_id =
          this.skuOptions[checkIfSkuExisted].id;
        this.formData.bill_products[index].code_description =
          this.skuOptions[checkIfSkuExisted].name;
      }
    },
    checkTag(e, index) {
      // const tagsAvailable = this.codeItems.filter((item) => item === text);
      // if (!tagsAvailable.length) {
      //   this.tag = ''
      // }
      // let filteredDataObj = this.allCode
      //       .filter(
      //         (data) =>
      //             data.full_code
      //               === text
      //         );
      //         const name = filteredDataObj[0].name
      //         this.formData.bill_products[index].description = name + ((filteredDataObj.length > 0 && this.currentCoil) ? " " + this.currentCoil.color + " " + this.currentCoil.size + " " + this.currentCoil.brand : "")
      // const name = e.name;
      // const insulation = this.formData.bill_check_pe;
      // var insulationText = "";
      // if (insulation) {
      //   if (this.isPE) {
      //     insulationText +=
      //       "ติดฉนวน PE " +
      //       (this.thickness != null
      //         ? this.thicknessOptions.find((o) => o.uuid === this.thickness)
      //             .name
      //         : "");
      //   }
      //   if (this.isPU) {
      //     insulationText +=
      //       "ติดฉนวน PU " +
      //       (this.thickness != null
      //         ? this.thicknessOptions2.find((o) => o.uuid === this.thickness)
      //             .name
      //         : "");
      //     insulationText +=
      //       this.insulator_material != null
      //         ? " " +
      //           this.insulatorMaterialOptions.find(
      //             (o) => o.uuid === this.insulator_material
      //           ).name
      //         : "";
      //     insulationText += this.currentCoil2
      //       ? " ใช้ท้อง " +
      //         this.currentCoil2?.size +
      //         " " +
      //         this.currentCoil2?.color +
      //         " " +
      //         this.currentCoil2?.brand
      //       : "";
      //   }
      // }
      // this.formData.bill_products[index].description =
      //   name +
      //   (e && this.currentCoil
      //     ? " " +
      //       this.currentCoil.color +
      //       " " +
      //       this.currentCoil.size +
      //       " " +
      //       this.currentCoil.brand +
      //       " " +
      //       insulationText
      //     : "");
      // this.formData.bill_products[index].sku_id = e.id
    },
    async onInitSellProductTypeList() {
      try {
        const result = await this.$axios.get(`${this.$host}/api/v1/skus`);
        if (result.status === 200) {
          const data = result.data.data;
          this.allCode = data;
          this.codeItems = data.map((d) => d.full_code);
        }
      } catch (error) {
        this.onExceptionHandler(error.response.data.message);
      }
    },
    lengthChanged(new_val, index) {
      this.reCalculate(index);
    },
    quantityChanged(new_val, index) {
      this.reCalculate(index);
    },
    unitPriceChanged(new_val, index) {
      this.reCalculate(index);
    },
    reCalculate(index) {
      const obj = this.formData.bill_products[index];
      if (parseFloat(obj.length) > 0 && parseFloat(obj.quantity) > 0) {
        obj.total_length = (
          parseFloat(obj.length) * parseFloat(obj.quantity)
        ).toFixed(2);
        obj.total_price = (
          parseFloat(obj.total_length) * parseFloat(obj.unit_price)
        ).toFixed(2);
      } else if (obj.length === "0") {
        obj.total_length = parseFloat(obj.quantity);
        obj.total_price =
          parseFloat(obj.total_length) * parseFloat(obj.unit_price);
      } else if (obj.quantity === "0") {
        obj.total_length = parseFloat(obj.length);
        obj.total_price =
          parseFloat(obj.total_length) * parseFloat(obj.unit_price);
      } else {
        obj.total_length = 1;
        obj.total_price =
          parseFloat(obj.total_length) * parseFloat(obj.unit_price);
      }

      // if (this.formData.bill_tax_type === 1) {
      //   this.total = parseFloat(
      //     parseFloat(this.subtotal) -
      //       parseFloat(this.formData.bill_discount_amt) +
      //       this.calculateVat()
      //   );
      // } else if (this.formData.bill_tax_type === 2) {
      //   this.total = parseFloat(
      //     parseFloat(this.subtotal) - parseFloat(this.formData.bill_discount_amt)
      //   );
      //   this.subtotal = this.total - this.calculateVat();
      // } else {
      //   this.total = parseFloat(
      //     parseFloat(this.subtotal) -
      //       parseFloat(this.formData.bill_discount_amt) +
      //       this.calculateVat()
      //   );
      // }
    },
    calculateVat() {
      if (this.formData.bill_tax_type === 1) {
        return parseFloat(this.subtotal) * 0.07;
      } else if (this.formData.bill_tax_type === 2) {
        return this.total * (7 / 107);
      } else {
        return 0;
      }
    },
    handleCreateEvent(event) {
      if (event.status) {
        this.create.Id = null;
        this.create.state = false;
        setTimeout(() => {
          const currentPage = this.currentPage;
          this.onInitUsers(currentPage);
        }, 500);
      }
    },
    clearInitData() {
      this.formData.product_code = "";
      this.formData.product_name = "";
      this.formData.product_length = 0;
      this.formData.balance = 0;
      this.formData.product_qty = 0;
      this.formData.sale_unit = "";
      this.formData.price_per_unit = 0;
      this.formData.unit = "";
      this.formData.total_price = 0;
      this.formData.remaining_length = 0;
      this.formData.length_being_cut = 0;
      this.formData.after_being_cut = 0;
    },
    onclear() {
      this.formData.bill_cut_pattern = null; // รูปแบบการตัด
      this.formData.bill_check_pe = false; // ติด PE ?
      this.formData.bill_code = ""; // รหัส
      this.formData.bill_name = ""; // ชื่อ
      this.formData.bill_price_m = 0; // ราคา/ม.
      this.formData.bill_except_e = 0; // เว้นปลาย(cm)
      this.formData.bill_except_h = 0; // เว้นหัว(cm)
      this.formData.bill_uuid = "";
      this.formData.product_code = ""; // รหัสสินค้า/บาร์โค๊ด
      this.formData.product_name = ""; // รายละเอียดสินค้า/รายการสินค้า
      this.formData.balance = 0;
      this.formData.product_length = 1; // ยาว(ม)
      this.formData.product_qty = 1; // จำนวน
      this.formData.sale_unit = ""; // หน่วยนับขาย
      this.formData.price_per_unit = 0; // ราคา/หน่วย
      this.formData.unit = ""; // หน่วย
      this.formData.total_price = 0; // จำนวนเงิน
      this.formData.bill_product_note = ""; // หมายเหตุรายการ
      this.formData.bill_total_qty = 0; // รวมจำนวน(หน่วย)
      this.formData.bill_total_length = 0; // รวมยาว(m)
      this.formData.bill_length_use = 0; // รวมยาวใช้จริง(m)
      this.formData.bill_products = []; // สินค้า (ตาราง)
      this.formData.remaining_length = 0;
      this.formData.length_being_cut = 0;
      this.formData.after_being_cut = 0;
    },
    /** คิดความยาวรวม คงเหลือ ตัด */
    onCalulatorLength() {
      this.formData.remaining_length = this.formData.balance;
      this.formData.length_being_cut = this.formData.product_length;

      this.formData.after_being_cut = 0;
      if (this.formData.balance && this.formData.product_length) {
        this.formData.after_being_cut = Number(
          (this.formData.balance - this.formData.product_length).toFixed(2)
        );
        // .toLocaleString(
        //   "th-TH",
        //   {
        //     minimumFractionDigits: 2
        //   }
        // )
      }
    },
    /** เปิด dialog ค้นหาข้อมูล PE*/
    onSelectGoodsPEHandler() {
      this.goodsPe = !this.goodsPe;
    },
    /** เปิด dialog ค้นหาข้อมูล PE*/
    onSelectPE() {
      this.thickness = null;
      this.insulator_material = null;

      if (this.isPE) {
        this.isPE = true;
      } else {
        this.isPE = true;
        this.isPU = false;
      }
    },
    /** เปิด dialog ค้นหาข้อมูล PE*/
    onSelectPU() {
      this.thickness = null;
      this.insulator_material = null;

      if (this.isPU) {
        this.isPU = true;
      } else {
        this.isPU = true;
        this.isPE = false;
      }
    },
    /**เพิ่มข้อมูล PE */
    onInitGoodsPESettings(e) {
      this.goodsPe = false;
      if (e.status) {
        if (e.status) {
          this.formData.bill_price_m = e.data.price;
          this.formData.bill_except_e = e.data.except_e;
          this.formData.bill_except_h = e.data.except_h;
          this.formData.bill_code = e.data.code;
          this.formData.bill_name = e.data.name;
          this.onInitPrepareData(e.data);
        }
      }
    },
    /** กดเลือกแล้วกดลบออก */
    onRemoveSelected() {
      if (this.selectedRows.length > 0) {
        const data = this.selectedRows[0];
        const index = _.findIndex(this.formData.bill_products, function (o) {
          return o.bill_uuid == data.bill_uuid;
        });
        this.formData.bill_products.splice(index, 1);

        if (this.formData.bill_products.length === 0) {
          this.clearInitData();
        }
      }
    },
    /** เปิด dialog ค้นหาข้อมูลม้วนคอยล์*/
    onOpenCoinListHandler() {
      this.coinlistEnabled = !this.coinlistEnabled;
    },
    onOpenCoinListHandler2() {
      this.coinlistEnabled2 = !this.coinlistEnabled2;
    },
    onOpenCoinListHandler3(index) {
      this.coinlistEnabled3 = !this.coinlistEnabled3;
      this.rollIndex = index;
    },
    /** เปิด dialog ค้นหาข้อมูลสินค้าสำเร็จรูป*/
    onSelectGoodsHandler() {
      this.isShowingMiscellaneous = !this.isShowingMiscellaneous;
    },
    clearInitDataNote() {},
    onCalcMeasure() {
      this.formData.bill_total_qty = 0;
      this.formData.bill_total_length = 0;
      this.formData.bill_length_use = 0;

      const products = this.formData.bill_products;
      for (let index = 0; index < products.length; index++) {
        const element = products[index];
        this.formData.bill_total_qty += parseFloat(element.qty);
        this.formData.bill_total_length += parseFloat(element.length);
        this.formData.bill_length_use += parseFloat(element.length_use);
      }
    },
    onInitPrepareData(item) {
      this.formData.bill_uuid = item.uuid;
      this.formData.product_code = item.code;
      this.formData.product_name = item.name;
      this.formData.balance = item.balance;
      this.formData.price_per_unit = item.price;
      this.formData.unit = item.unit;
      this.formData.sale_unit = item.sale_unit;

      if (item.balance > 0) {
        this.getTotalPrice();
      }
    },
    getTotalPrice() {
      this.formData.total_price = Number(
        (
          this.formData.product_length *
          this.formData.product_qty *
          this.formData.price_per_unit
        ).toFixed(2)
      );
    },
    /** เปิด dialog ค้นหาข้อมูลการตัด*/
    onSelectCuttingHandler() {
      this.coincuttingEnabled = !this.coincuttingEnabled;
    },
    /**เพิ่มข้อมูล การตัด */
    onInitCoinCuttingListSettings(e) {
      this.coincuttingEnabled = false;
      if (e.status) {
        this.formData.bill_cut_pattern = e.data.uuid;
        this.onCreateProductHandler();
      }
    },
    /** เพิ่มข้อมูล ก่อน assign ลงตาราง */
    onInitCoinListSettings(e) {
      this.coinlistEnabled = false;
      if (e.status) {
        this.currentCoil = e.data;
        // this.onInitPrepareData(e.data);
      }
    },
    onInitCoinListSettings2(e) {
      this.coinlistEnabled2 = false;
      if (e.status) {
        this.currentCoil2 = e.data;
      }
    },
    onInitCoinListSettings3(e) {
      this.coinlistEnabled3 = false;
      if (e.status) {
        this.formData.bill_products[e.index].roll =
          e.data.color + " " + e.data.size + " " + e.data.brand;
        this.formData.bill_products[e.index].roll_id = e.data.id;
      }
    },
    /** สร้างข้อมูลบนตาราง */
    onCreateProductHandler() {
      if (this.formData.product_code) {
        const product = this.formData.bill_products;
        if (product.length === 0) {
          const total_length_use =
            this.formData.product_length * this.formData.product_qty;
          product.push({
            uuid: product.length + 1,
            code: this.formData.product_code,
            name: this.formData.product_name,
            length: this.formData.product_length,
            balance: this.formData.balance,
            qty: this.formData.product_qty,
            sale_unit: this.formData.sale_unit,
            price_per_unit: this.formData.price_per_unit,
            unit: this.formData.unit,
            total_price: this.formData.total_price,
            length_use: total_length_use,
            total_length: total_length_use,
          });
        } else {
          const hasData = _.find(this.formData.bill_products, {
            uuid: this.formData.bill_uuid,
          });
          const total_length_use =
            this.formData.product_length * this.formData.product_qty;
          if (!hasData) {
            product.push({
              uuid: product.length + 1,
              code: this.formData.product_code,
              name: this.formData.product_name,
              length: this.formData.product_length,
              balance: this.formData.balance,
              qty: this.formData.product_qty,
              sale_unit: this.formData.sale_unit,
              price_per_unit: this.formData.price_per_unit,
              unit: this.formData.unit,
              total_price: this.formData.total_price,
              length_use: total_length_use,
              total_length: total_length_use,
            });
          } else {
            (hasData.bill_uuid = this.formData.bill_uuid),
              (hasData.code = this.formData.product_code),
              (hasData.name = this.formData.product_name),
              (hasData.length = this.formData.product_length),
              (hasData.balance = this.formData.balance),
              (hasData.qty = this.formData.product_qty),
              (hasData.sale_unit = this.formData.sale_unit),
              (hasData.price_per_unit = this.formData.price_per_unit),
              (hasData.unit = this.formData.unit),
              (hasData.total_price = this.formData.total_price),
              (hasData.length_use = total_length_use),
              (hasData.total_length = total_length_use);
          }
        }
      }
      this.clearInitData();
      this.onCalcMeasure();
      this.$refs.table.clearSelected();
    },
    /** กดเลือกสินค้า */
    onRowSelected(items) {
      this.selectedRows = items;
      if (items.length > 0) {
        const data = this.selectedRows[0];
        setTimeout(() => {
          this.formData.product_length = parseFloat(data.length);
          this.formData.product_qty = parseFloat(data.qty);
          this.formData.bill_uuid = data.uuid;
          this.formData.product_code = data.code;
          this.formData.product_name = data.name;
          this.formData.balance = data.balance;
          this.formData.price_per_unit = data.price_per_unit;
          this.formData.unit = data.unit;
          this.formData.sale_unit = data.sale_unit;

          return true;
        }, 100);
      } else {
        this.clearInitData();
      }
    },
    /** แจ้งเตือนเลือกประเภทการตัด */
    onrequestCreate() {
      if (this.formData.bill_cut_pattern) {
        this.onCreateProductHandler();
      } else {
        this.$swal({
          width: "29rem",
          padding: "1.825em",
          html:
            '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
            '<div class="d-flex flex-column">' +
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>' +
            '<span class="text-base text-left text-gray-500">เลือกประเภทการตัด</span>' +
            "</div>" +
            "</div>",
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: "swal2-custom-rounded",
            closeButton: "text-3xl swal2-custom-close",
            actions: "justify-content-end",
            contant: "d-flex flex-row justify-content-around px-2",
            title: "d-none",
            confirmButton:
              "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton:
              "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          },
          cancelButtonColor: "#dc4a38",
          cancelButtonText: "ยกเลิก",
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          showCloseButton: true,
        }).then(async (confirm) => {
          if (confirm.value) {
            this.onSelectCuttingHandler();
          }
        });
      }
    },
    onAddProduct() {
      var blank_row = {
        code: "",
        roll: "",
        insulator_roll: "",
        roll_id: null,
        code_description: "",
        description: "",
        length: "0",
        quantity: "0",
        total_length: "0",
        unit_price: "0",
        total_price: "0",
        unit_name: "",
        remark: "",
        sku_id: null,
        is_insulator: false,
        isPE: true,
        isPU: false,
        thickness: null,
        insulator_material: null,
        insulator_roll_id: null,
        insulator_material_id: null,
      };
      this.formData.bill_products.splice(0, 0, blank_row);

      // this.formData.bill_products.push(blank_row);
    },
    onSettingClose(e) {
      this.isSettingOpened = false;
      if (e.status) {
        this.formData.bill_products[e.index] = e.data;
      }
    },
    /**เพิ่มข้อมูล สินค้าสำเร็จรูป */
    onSelectMiscellaneous(e) {
      this.isShowingMiscellaneous = false;
      if (e.status) {
        if (e.data) {
          var data_row = {
            code: e.data.code,
            roll: e.data.roll,
            insulator_roll: e.data.insulator_roll,
            roll_id: e.data.roll_id,
            code_description: e.data.code_description,
            description: e.data.name,
            length: "0",
            quantity: "0",
            total_length: "",
            unit_price: e.data.price,
            total_price: "0",
            unit_name: e.data.unit_name,
            remark: e.data.note,
            is_insulator: e.data.is_insulator,
            isPE: e.data.isPE,
            isPU: e.data.isPU,
            thickness: e.data.thickness,
            insulator_material: e.data.insulator_material,
            insulator_roll_id: e.data.insulator_roll_id,
            insulator_material_id: e.data.insulator_material_id,
          };
          this.formData.bill_products.splice(
            this.insertAfterRowIndex,
            0,
            data_row
          );
          // this.formData.bill_products.push(blank_row);
        }
      }
    },
    oninsert_product(index, type) {
      if (type === 1) {
        var blank_row = {
          code: "",
          roll: "",
          insulator_roll: "",
          roll_id: null,
          code_description: "",
          description: "",
          length: "0",
          quantity: "0",
          total_length: "0",
          unit_price: "0",
          total_price: "0",
          unit_name: "",
          remark: "",
          sku_id: null,
          is_insulator: false,
          isPE: true,
          isPU: false,
          thickness: null,
          insulator_material: null,
          insulator_roll_id: null,
          insulator_material_id: null,
        };
        this.formData.bill_products.splice(index + 1, 0, blank_row);
      } else {
        this.insertAfterRowIndex = index + 1;
        this.onSelectGoodsHandler();
      }
    },
    oncopy_product(index) {
      const currentProducts = this.formData.bill_products;
      const targetProduct = currentProducts[index];
      var copied_row = {
        code: targetProduct.code,
        roll: targetProduct.roll,
        insulator_roll: targetProduct.insulator_roll,
        roll_id: targetProduct.roll_id,
        code_description: targetProduct.code_description,
        description: targetProduct.description,
        length: targetProduct.length,
        quantity: targetProduct.quantity,
        total_length: targetProduct.total_length,
        unit_price: targetProduct.unit_price,
        total_price: targetProduct.total_price,
        unit_name: targetProduct.unit_name,
        remark: targetProduct.remark,
        sku_id: targetProduct.sku_id,
        is_insulator: targetProduct.is_insulator,
        isPE: targetProduct.isPE,
        isPU: targetProduct.isPU,
        thickness: targetProduct.thickness,
        insulator_material: targetProduct.insulator_material,
        insulator_roll_id: targetProduct.insulator_roll_id,
        insulator_material_id: targetProduct.insulator_material_id,
      };
      this.formData.bill_products.splice(index + 1, 0, copied_row);
    },
    onremove_product(index) {
      this.formData.bill_products.splice(index, 1);
    },
    async loadSkuOptions() {
      this.skuOptions = [];
      try {
        const result = await this.$axios.get(`${this.$host}/api/v1/master/sku`);
        if (result) {
          this.skuOptions = result.data;
        }
      } catch (error) {
        this.busy = false;
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async loadItems(q) {
      this.busy = true;
      this.$parent.loading = true;
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/bill-item/${q}`
        );
        if (result) {
          const data = result.data;

          data.data.map((item) => {
            item.roll = "";
            return item;
          });

          this.formData.bill_products = data.data;
          this.formData.bill_quotation_no = data.quotation_no;
          this.formData.bill_quotation_id = data.quotation_id;
          this.formData.bill_customer_addr = data.bill_customer_addr;
          this.formData.bill_customer_code = data.bill_customer_code;
          this.formData.bill_customer_name = data.bill_customer_name;
          this.formData.bill_customer_shipping_address =
            data.bill_customer_shipping_address;
          this.formData.customer_id = data.customer_id;
          this.formData.bill_discount_amt = data.discount;
          this.formData.bill_tax_type = data.vatable;
        }
        this.busy = false;
        this.$parent.loading = false;
      } catch (error) {
        this.busy = false;
        this.$parent.loading = false;

        this.onExceptionHandler(error.response.data.message);
      }
    },
  },
  async created() {
    // await this.onInitSellProductTypeList()
    await this.loadSkuOptions();

    if (this.$route.query.q) {
      await this.loadItems(this.$route.query.q);
    }
  },
};
