import Customers from "./components/customers";
import DocumentInfo from "./components/document";
import Products from "./components/products";
import Footer from "./components/summary";
import Loading from "@/components/widgets/Loading.vue";
import moment from "moment";
import DatePicker from "vue2-datepicker";

export default {
  data() {
    return {
      loading: false,
      formData: {
        document_no: "",
        doc_date: new Date(),
        ref_doc: "",
        manipulator: "",
        remark: "",

        bill_no: "",
        customer_id: "",
        bill_customer_code: "",
        bill_customer_name: "",
        bill_customer_phone_number: "",
        bill_customer_addr: "",
        bill_customer_shipping_address: "",
        bill_payment_method: null,
        bill_credit_detail: null,
        bill_duedate: null,
        bill_shipping_method: null,
        bill_shipping_date: null,
        bill_quotation_no: "",
        bill_quotation_id: null,
        bill_seller: "",
        bill_seller_id: null,
        bill_manipulator: "",
        bill_date: new Date(),
        bill_reference: "",
        bill_uuid: "",
        bill_cut_pattern: null,
        bill_check_pe: false,
        bill_code: "",
        bill_name: "",
        bill_price_m: 0,
        bill_products: [],
        bill_product_note: "",
        bill_total_qty: 0,
        bill_total_length: 0,
        bill_length_use: 0,
        bill_note: "",
        bill_summary: 0,
        bill_cod: 0,
        bill_discount_percentage: 0,
        bill_discount_amt: 0,
        bill_after_discount: 0,
        bill_deposit_number: "",
        bill_deposit_amt: 0,
        bill_after_deposit: 0,
        bill_nettotal_amt: 0,
        bill_before_vat: 0,
        bill_tax_use: true,
        bill_tax_type: 0,
        bill_percent_tax: 7,
        bill_value_added_tax: 0,
        bill_grand_total: 0,

        product_length: 1,
        product_qty: 1,
        price_per_unit: 0,
        remaining_length: 0,
        length_being_cut: 0,
        after_being_cut: 0,
        deposit_document_id: null,
        deposit_document_no: "",
      },
    };
  },
  components: {
    customers: Customers,
    "document-info": DocumentInfo,
    products: Products,
    "footer-component": Footer,
    Loading,
    DatePicker,
  },
  methods: {
    onCustomerData(e) {
      if (e.customer) {
        for (const [key, value] of Object.entries(e.customer)) {
          this.formData[key] = value;
        }
      }
    },
    onDocumentData(e) {
      if (e.document) {
        for (const [key, value] of Object.entries(e.document)) {
          this.formData[key] = value;
        }
      }
    },
    onBilldetail(e) {
      if (e.detail) {
        for (const [key, value] of Object.entries(e.detail)) {
          this.formData[key] = value;
        }
      }
    },
    onSummaryData(e) {
      if (e.summary) {
        for (const [key, value] of Object.entries(e.summary)) {
          this.formData[key] = value;
        }
      }
    },
    resetFormData() {
      this.formData.customer_id = "";
      this.formData.bill_no = "";
      this.formData.bill_customer_code = "";
      this.formData.bill_customer_name = "";
      this.formData.bill_customer_phone_number = "";
      this.formData.bill_customer_addr = "";
      this.formData.bill_customer_shipping_address = "";
      this.formData.bill_payment_method = null;
      this.formData.bill_credit_detail = null;
      this.formData.bill_duedate = null;
      this.formData.bill_shipping_method = null;
      this.formData.bill_shipping_date = null;
      this.formData.bill_quotation_no = "";
      this.formData.bill_quotation_id = null;
      this.formData.bill_seller = "";
      this.formData.bill_seller_id = null;
      this.formData.bill_manipulator = "";
      this.formData.bill_date = new Date();
      this.formData.bill_reference = "";
      this.formData.bill_uuid = "";
      this.formData.bill_cut_pattern = null;
      this.formData.bill_check_pe = false;
      this.formData.bill_code = "";
      this.formData.bill_name = "";
      this.formData.bill_price_m = 0;
      this.formData.bill_products = [];
      this.formData.bill_product_note = "";
      this.formData.bill_total_qty = 0;
      this.formData.bill_total_length = 0;
      this.formData.bill_length_use = 0;
      this.formData.bill_note = "";
      this.formData.bill_summary = 0;
      this.formData.bill_cod = 0;
      this.formData.bill_discount_percentage = 0;
      this.formData.bill_discount_amt = 0;
      this.formData.bill_after_discount = 0;
      this.formData.bill_deposit_amt = "";
      this.formData.bill_after_deposit = "";
      this.formData.bill_nettotal_amt = 0;
      this.formData.bill_before_vat = 0;
      this.formData.bill_tax_use = true;
      this.formData.bill_tax_type = 0;
      this.formData.bill_percent_tax = 7;
      this.formData.bill_value_added_tax = 0;
      this.formData.bill_grand_total = 0;

      this.formData.remaining_length = 0;
      this.formData.length_being_cut = 0;
      this.formData.after_being_cut = 0;
      (this.formData.deposit_document_id = null),
        (this.formData.deposit_document_no = "");
    },
    onCreateBillForm(item) {
      const formData = {
        // customer component.
        customer_id: item.customer_id,
        bill_no: item.bill_no,
        bill_customer_code: item.bill_customer_code,
        bill_customer_name: item.bill_customer_name,
        bill_customer_addr: item.bill_customer_addr,
        bill_customer_phone_number: item.bill_customer_phone_number,
        bill_customer_shipping_address: item.bill_customer_shipping_address,

        // document component.
        bill_payment_method: item.bill_payment_method,
        bill_credit_detail: item.bill_credit_detail,
        bill_duedate: item.bill_duedate,
        bill_shipping_method: item.bill_shipping_method,
        // bill_shipping_date: item.bill_shipping_date ? moment(item.bill_shipping_date).format('YYYY-MM-DD') : null,
        bill_shipping_date: item.bill_shipping_date,

        bill_quotation_no: item.bill_quotation_no,
        bill_quotation_id: item.bill_quotation_id,
        bill_date: item.bill_date,
        bill_seller: item.bill_seller,
        bill_seller_id: item.bill_seller_id,
        bill_manipulator: item.bill_manipulator,
        bill_reference: item.bill_reference,
        deposit_document_id: item.deposit_document_id,
        deposit_document_no: item.deposit_document_no,
        bill_deposit_amt: item.bill_deposit_amt,

        // products component.
        bill_uuid: "",
        bill_cut_pattern: item.bill_cut_pattern,
        bill_check_pe: item.bill_check_pe,
        bill_code: item.bill_code,
        bill_name: item.bill_name,
        bill_price_m: parseFloat(item.bill_price_m),
        bill_except_h: parseFloat(item.bill_except_h),
        bill_except_e: parseFloat(item.bill_except_e),
        bill_products: item.bill_products,
        bill_product_note: item.bill_product_note,
        bill_total_qty: item.bill_total_qty,
        bill_total_length: item.bill_total_length,
        bill_length_use: item.bill_length_use,

        // summary component.
        bill_note: item.bill_note,
        bill_summary: item.bill_summary,
        bill_cod: item.bill_cod,
        bill_discount_percentage: parseFloat(item.bill_discount_percentage),
        bill_discount_amt: parseFloat(item.bill_discount_amt),
        bill_after_discount: item.bill_after_discount,
        bill_deposit_number: item.bill_deposit_number,
        bill_after_deposit: item.bill_after_deposit,
        bill_tax_use: item.bill_tax_use,
        bill_tax_type: item.bill_tax_type,
        bill_percent_tax: item.bill_percent_tax,
        bill_nettotal_amt: item.bill_nettotal_amt,
        bill_before_vat: parseFloat(item.bill_before_vat),
        bill_value_added_tax: parseFloat(item.bill_value_added_tax),
        bill_grand_total: parseFloat(item.bill_grand_total),
      };
      return formData;
    },
    onFormBuild(item) {
      // customer component.
      this.formData.customer_id = item.customer_id;
      this.formData.bill_no = item.bill_no;

      this.formData.bill_customer_code = item.bill_customer_code;
      this.formData.bill_customer_name = item.bill_customer_name;
      this.formData.bill_customer_addr = item.bill_customer_addr;
      this.formData.bill_customer_phone_number =
        item.bill_customer_phone_number;
      this.formData.bill_customer_shipping_address =
        item.bill_customer_shipping_address;

      // document component.
      this.formData.bill_payment_method = item.bill_payment_method;
      this.formData.bill_credit_detail = item.bill_credit_detail;
      this.formData.bill_duedate =
        item.bill_duedate != null ? new Date(item.bill_duedate) : null;
      this.formData.bill_shipping_date = new Date(item.bill_shipping_date);
      //  this.formData.bill_duedate = item.bill_duedate ? moment(item.bill_duedate).format('YYYY-MM-DD') : null,
      //  this.formData.bill_shipping_date = item.bill_shipping_date ? moment(item.bill_shipping_date).format('YYYY-MM-DD') : null
      this.formData.bill_date = new Date(item.bill_date);
      this.formData.bill_shipping_method = item.bill_shipping_method;
      this.formData.bill_quotation_no = item.bill_quotation_no;
      this.formData.bill_quotation_id = item.bill_quotation_id;
      this.formData.bill_seller = item.bill_seller;
      this.formData.bill_seller_id = item.bill_seller_id;
      this.formData.bill_manipulator = item.bill_manipulator;
      this.formData.bill_reference = item.bill_reference;
      (this.formData.deposit_document_id = item.deposit_document_id),
        (this.formData.deposit_document_no = item.deposit_document_no);

      // products component.
      this.formData.bill_uuid = item.bill_uuid;
      this.formData.bill_cut_pattern = item.bill_cut_pattern;
      this.formData.bill_check_pe = item.bill_check_pe ? true : false;
      this.formData.bill_code = item.bill_code;
      this.formData.bill_name = item.bill_name;
      this.formData.bill_price_m = item.bill_price_m;
      this.formData.bill_except_h = item.bill_except_h;
      this.formData.bill_except_e = item.bill_except_e;
      this.formData.bill_products = item.products;
      this.formData.bill_product_note = item.bill_product_note;
      this.formData.bill_total_qty = item.bill_total_qty;
      this.formData.bill_total_length = item.bill_total_length;
      this.formData.bill_length_use = item.bill_length_use;

      // summary component.
      this.formData.bill_note = item.bill_note;
      this.formData.bill_summary = item.bill_summary;
      this.formData.bill_cod = item.bill_cod;
      this.formData.bill_discount_percentage = item.bill_discount_percentage;
      this.formData.bill_discount_amt = item.bill_discount_amt;
      this.formData.bill_after_discount = item.bill_after_discount;
      this.formData.bill_deposit_number = item.bill_deposit_number;
      this.formData.bill_deposit_amt = item.bill_deposit_amt;
      this.formData.bill_after_deposit = item.bill_after_deposit;
      this.formData.bill_tax_use = item.bill_tax_use ? true : false;
      this.formData.bill_tax_type = item.bill_tax_type;
      this.formData.bill_percent_tax = item.bill_percent_tax
        ? item.bill_percent_tax
        : 7;
      this.formData.bill_nettotal_amt = item.bill_nettotal_amt;
      this.formData.bill_before_vat = item.bill_before_vat;
      this.formData.bill_value_added_tax = item.bill_value_added_tax;
      this.formData.bill_grand_total = item.bill_grand_total;

      this.formData.price_per_unit = 0;
      this.formData.product_code = "";
      this.formData.product_length = 0;
      this.formData.product_name = "";
      this.formData.product_qty = 0;
      this.formData.sale_unit = "";
      this.formData.total_price = 0;
      this.formData.unit = "";
    },
    /** บันทึกข้อมูล */
    async onSubmitFormHandler(e) {
      if (this.formData.customer_id === "") {
        this.onExceptionHandler("กรุณาระบุข้อมูลลูกค้า");
        return;
      }

      if (this.formData.bill_customer_shipping_address === "") {
        this.onExceptionHandler("กรุณาระบุที่อยู่จัดส่ง");
        return;
      }

      if (
        this.formData.bill_shipping_method === null ||
        this.formData.bill_shipping_date === null
      ) {
        this.onExceptionHandler("กรุณาระบุวิธีการจัดส่งและวันกำหนดส่ง");
        return;
      }

      if (this.formData.bill_products.length === 0) {
        this.onExceptionHandler("กรุณาเลือกข้อมูลสินค้า");
        return false;
      }

      if (
        this.formData.bill_payment_method === null ||
        this.formData.bill_duedate === null
      ) {
        this.onExceptionHandler("กรุณาระบุวิธีการชำระเงินคงค้างและกำหนดชำระ");
        return false;
      }

      this.loading = true;
      const formData = this.onCreateBillForm(this.formData);
      let result = null;
      if (this.$route.params?.id) {
        result = await this.$store.dispatch(
          "Bills/update",
          { form: JSON.stringify(formData), Id: this.$route.params?.id },
          { root: true }
        );
      } else {
        result = await this.$store.dispatch(
          "Bills/create",
          JSON.stringify(formData),
          { root: true }
        );
      }

      setTimeout(() => {
        this.loading = false;
        if (result?.status === 201 || result?.status === 200) {
          // if (!this.$route.params?.id) {
          //   this.$refs.customer.onclear()
          //   this.$refs.document.onclear()
          //   this.$refs.product.onclear()
          //   this.$refs.footer.onclear()
          // }

          this.$swal({
            width: "29rem",
            padding: "1.825em",
            html:
              '<div class="d-flex flex-row align-items-center">' +
              '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
              '<div class="d-flex flex-column">' +
              '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>' +
              '<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>' +
              "</div>" +
              "</div>",
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: "swal2-custom-rounded",
              closeButton: "text-3xl swal2-custom-close",
              actions: "justify-content-end",
              contant: "d-flex flex-row justify-content-around px-2",
              title: "d-none",
              confirmButton:
                "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton:
                "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            },
            cancelButtonColor: "#dc4a38",
            cancelButtonText: "ยกเลิก",
            confirmButtonText: "ตกลง",
            showCancelButton: false,
            showCloseButton: true,
          }).then(async (confirm) => {
            if (confirm.value) {
              this.$router.push("/billing");

              // const goods_note_path = '/billing/goods_note'
              // const routePath = '/billing/goods_production'

              // const urls = [
              //   window.location.origin + goods_note_path,
              //   window.location.origin + routePath
              // ]
              // urls.forEach(function(url) {
              //   window.open(url, '_blank')
              // })
            }
          });
        } else {
          this.onExceptionHandler(
            "ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง"
          );
        }
      }, 1000);
    },
    async onInitData() {
      if (this.$route.query.q) {
        console.log(this.$route.query.q)
        // try {
        //   this.loading = true;
        //   setTimeout(async () => {
        //     const param = {
        //       _id: this.$route.params?.id,
        //     };
        //     const result = await this.$store.dispatch("Bills/get", param, {
        //       root: true,
        //     });
        //     if (result.status === 200) {
        //       this.loading = false;
        //       this.onFormBuild(result.data);
        //     }
        //   }, 1000);
        // } catch (error) {
        //   this.loading = false;
        //   this.onExceptionHandler(error.response.data.message);
        // }
      }
    },
    cancelDocument() {
      this.$swal({
        width: "29rem",
        padding: "1.825em",
        html:
          '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
          '<div class="d-flex flex-column">' +
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการยกเลิกใบผลิตนี้หรือไม่</span>' +
          '<span class="text-base text-left text-gray-500">หากกด "ตกลง" ข้อมูลทั้งหมดจะหายไป ท่านแน่ใจหรือไม่?</span>' +
          "</div>" +
          "</div>",
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          popup: "swal2-custom-rounded",
          closeButton: "text-3xl swal2-custom-close",
          actions: "justify-content-end",
          contant: "d-flex flex-row justify-content-around px-2",
          title: "d-none",
          confirmButton:
            "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          cancelButton:
            "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
        },
        cancelButtonColor: "#dc4a38",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
        showCancelButton: true,
        showCloseButton: true,
      }).then(async (confirm) => {
        if (confirm.value) {
          this.$router.push("/production");
        }
      });
    },
  },
  mounted() {
    this.onInitData();
  },
};
